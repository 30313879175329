






import { Component, Vue } from 'vue-property-decorator';
import PriceListFuture from '@/components/pricelistFuture.vue';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {
    PriceListFuture
  },
  metaInfo: metaInfo({
    title: 'Nový Ceník | Kakadoo',
    url: buildUrl('/novy-cenik'),
    description: 'Ceny dodávek již od 590 Kč/den. Žádnými skrytými poplatky vás nepřekvapíme.'
  })
})
export default class PriceListPage extends Vue {}
