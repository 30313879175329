



















































































import { Component, Vue } from 'vue-property-decorator';
import carService from '../services/cars.service';
import Tooltip from '@/components/tooltip.vue';
import { CarPriceListInfo } from '../models/carsPricelist';
import getDayString from '../utils/getDayString';
import getPercentFromCoef from '../utils/getPercentFromCoef';
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons';
import { FeeSorted } from '@/models/fee';
import feeService from '@/services/fee.service';
import { RENTAL } from '@/models/carType';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

@Component({
  components: {
    Tooltip,
    ChevronRightIcon,
    ChevronLeftIcon
  }
})
export default class PriceList extends Vue {
  public cars: CarPriceListInfo[] = [];
  public dayString = getDayString;
  public getPercent = getPercentFromCoef;

  public fees: FeeSorted[] = [];
  public priceListsByCities: CarPriceListInfo[][] = [];

  async mounted() {
    this.cars = await carService.getCarsPriceList('pricesPerDay20240301');
    this.fees = await feeService.getFees();
    this.dividePriceListsByCities();
  }

  goToRentalByCity(city: string) {
    this.$router.push({
      path: `${RENTAL}/${city}`,
      query: {
        from: moment().format(DEFAULT_DATE_FORMAT),
        to: moment().format(DEFAULT_DATE_FORMAT)
      }
    });
  }

  public getInWhichCityStringInCzech(city: string) {
    return `${
      (city === 'praha' && 'v Praze') || (city === 'zlin' && 've Zlíně') || (city === 'plzen' && 'v Plzni') || ''
    }`;
  }

  private dividePriceListsByCities() {
    // Divide the cars into smaller arrays based on city
    const carsByCity: { [city: string]: CarPriceListInfo[] } = {};
    this.cars.forEach((car) => {
      carsByCity[car.city] = carsByCity[car.city] || [];
      carsByCity[car.city].push(car);
    });
    this.priceListsByCities = Object.values(carsByCity);
  }
}
